<template>
  <div class="content-layout">
    <div class="product-operations">
      <div class="data-filter">
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">{{
            filter.label
          }}</a-select-option>
        </a-select>
        <a-input
          v-if="filterSelected != $constRepository.product.FILTER_OPTION_PRODUCT_PROTOCOL.value"
          v-model="searchKeyword"
          class="search-keyword"
          clearable
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-select allow-clear v-else v-model="searchKeyword" class="search-keyword" @change="doSearch">
          <a-select-option
            v-for="protocol in metaData.productProtocols"
            :key="protocol.value"
            :value="protocol.value"
            >{{ protocol.name }}</a-select-option
          >
        </a-select>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :data-source="tableDate"
      class="product-table"
      rowKey="productId"
      tableLayout="fixed"
      childrenColumnName="none"
    >
      <a-table-column title="产品ID" key="productId" align="center">
        <template slot-scope="scope">
          {{ scope.productId }}
        </template>
      </a-table-column>
      <a-table-column title="产品名称" key="name" align="center">
        <template slot-scope="scope">
          {{ scope.name }}
        </template>
      </a-table-column>
      <a-table-column title="中控内部驱动标识符" key="driver" align="center">
        <template slot-scope="scope">
          {{ scope.driver }}
        </template>
      </a-table-column>
      <a-table-column title="子设备数量" key="childrenCount" align="center">
        <template slot-scope="scope">
          {{ scope.childrenCount }}
        </template>
      </a-table-column>
      <a-table-column title="产品控制协议类型" key="protocol" align="center">
        <template slot-scope="scope">
          {{ productProtocols(scope.protocol) }}
        </template>
      </a-table-column>
      <a-table-column title="产品类型" key="type" align="center">
        <template slot-scope="scope">
          {{ productTypes(scope.type) }}
        </template>
      </a-table-column>
      <a-table-column title="用户是否可见" key="userVisible" align="center">
        <template slot-scope="scope">
          <div v-if="scope.userVisible">是</div>
          <div v-else>否</div>
        </template>
      </a-table-column>
      <a-table-column title="发布区域" key="regions" data-index="regions" align="center">
        <template slot-scope="scope">
          <span v-if="scope">
            {{ scope.map((region) => region.name).join('、') }}
          </span>
          <span v-else>-</span>
        </template>
      </a-table-column>
      <a-table-column title="操作" key="action" width="18%" align="center">
        <template slot-scope="scope">
          <a-button class="green-btn" ghost @click="handleDetail(scope)">详情</a-button>
        </template>
      </a-table-column>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  components: {},
  async mounted() {
    this.loadedPageData();
    await this.getMetaData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.product.FILTER_OPTION_PRODUCT_NAME.value,
      filterOptions: this.$constRepository.product.FILTER_OPTIONS,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      metaData: {},
    };
  },
  methods: {
    async listProducts() {
      const params = {
        name: this.requestParams.name,
        productId: this.requestParams.productId,
        driver: this.requestParams.driver,
        protocol: this.requestParams.protocol,
        detail: true,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.publicApi.productApi.getProducts(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'productId', undefined);
      this.$set(this.requestParams, 'driver', undefined);
      this.$set(this.requestParams, 'protocol', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_ID.value:
          this.$set(this.requestParams, 'productId', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_DRIVER.value:
          this.$set(this.requestParams, 'driver', this.searchKeyword);
          break;
        case this.$constRepository.product.FILTER_OPTION_PRODUCT_PROTOCOL.value:
          this.$set(this.requestParams, 'protocol', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listProducts();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProducts();
      }
    },
    handleDetail(record) {
      this.$router.push({ name: 'modelProductDetail', params: { id: record.productId } });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
  computed: {
    productProtocols() {
      return (protocol) => {
        const protocolName = this.metaData.productProtocols?.find((md) => md.value === protocol);
        return protocolName ? protocolName.name : '';
      };
    },
    productTypes() {
      return (type) => {
        const typeName = this.metaData.productTypes?.find((md) => md.value === type);
        return typeName ? typeName.name : '';
      };
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-operations {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .product-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
